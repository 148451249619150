.overlay {
  display: flex;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.64);
  height: 100%;
  justify-content: center;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
}

.cardBack {
  background-color: #f4f5f7;
  border-radius: 2px;
  margin: 48px 0 80px;
  overflow: hidden;
  position: relative;
  width: 600px;
  z-index: 25;
  padding: 16px;
}

.header {
  display: flex;
  align-items: center;
  margin: 0;
}

.cardName {
  font-size: 20px;
  line-height: 24px;
  margin: 0 8px;
}

.listName {
  color: #5e6c84;
  cursor: default;
  display: inline-block;
  margin: 4px 8px 4px 32px;
}

.section {
  margin-top: 8px;
  margin-bottom: 32px;
}

.sectionHeader {
  display: flex;
  align-items: center;
  margin: 0;

  .sectionName {
    font-size: 16px;
    line-height: 20px;
    display: inline-block;
    width: auto;
    margin: 0 0 0 8px;
    min-height: 18px;
    min-width: 40px;
  }
}

.sectionContent {
  margin: 8px 32px 0 32px;
}

.checkList {
  margin: 8px 32px 0 0;
}

.checkListItem {
  display: flex;
  align-items: center;

  justify-content: space-between;

  margin-bottom: 8px;

  .itemText {
    margin-left: 8px;
  }

  .itemAndName {
    display: flex;
    align-items: center;
  }

  .advancedCheckListFields {
    display: flex;
    gap: 8px;
    align-items: center;

    .badgeContainer {
      min-width: 188px;
    }
  }
}

.badgesSection {
  margin: 4px 8px 4px 32px;
  display: flex;
  flex-wrap: wrap;
}

.badgeContainer {
  display: flex;
  flex-direction: column;
  margin: 8px 0 8px 0;
}

.badges {
  display: flex;
  margin-right: 8px;
  flex-wrap: wrap;
}

.badgeLabel {
  color: #5e6c84;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.04em;
  line-height: 16px;
  text-transform: uppercase;
  display: block;
  line-height: 20px;
  margin: 0 8px 4px 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.cf {
    display: flex;
    align-items: center;
  }
}

.label {
  border-radius: 3px;
  box-sizing: border-box;
  display: block;
  float: left;
  font-weight: 400;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  margin: 0 4px 4px 0;
  min-width: 40px;
  padding: 0 12px;
  width: auto;
  color: #172b4d;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
  position: relative;
  padding-left: 28px;

  &::before {
    content: '';
    position: absolute;
    top: 12px;
    bottom: 4px;
    left: 8px;
    border-radius: 50%;
    width: 8px;
    height: 8px;
  }

  &.green_light {
    &::before {
      background-color: #b7ddb0;
    }
    background-color: #eef6ec;
  }

  &.green {
    &::before {
      background-color: #7bc86c;
    }
    background-color: #d6ecd2;
  }

  &.green_dark {
    &::before {
      background-color: #5aac44;
    }
    background-color: #b7ddb0;
  }

  &.yellow_light {
    &::before {
      background-color: #f5ea92;
    }
    background-color: #fdfae5;
  }

  &.yellow {
    &::before {
      background-color: #f5dd29;
    }
    background-color: #faf3c0;
  }

  &.yellow_dark {
    &::before {
      background-color: #e6c60d;
    }
    background-color: #f5ea92;
  }

  &.orange_light {
    &::before {
      background-color: #fad29c;
    }
    background-color: #fdf4e7;
  }

  &.orange {
    &::before {
      background-color: #ffaf3f;
    }
    background-color: #fce6c6;
  }

  &.orange_dark {
    &::before {
      background-color: #e79217;
    }
    background-color: #fad29c;
  }

  &.red_light {
    &::before {
      background-color: #efb3ab;
    }
    background-color: #fbedeb;
  }

  &.red {
    &::before {
      background-color: #ef7564;
    }
    background-color: #f5d3ce;
  }

  &.red_dark {
    &::before {
      background-color: #cf513d;
    }
    background-color: #efb3ab;
  }

  &.purple_light {
    &::before {
      background-color: #dfc0eb;
    }
    background-color: #f7f0fa;
  }

  &.purple {
    &::before {
      background-color: #cd8de5;
    }
    background-color: #eddbf4;
  }

  &.purple_dark {
    &::before {
      background-color: #a86cc1;
    }
    background-color: #dfc0eb;
  }

  &.blue_light {
    &::before {
      background-color: #8bbdd9;
    }
    background-color: #e4f0f6;
  }

  &.blue {
    &::before {
      background-color: #5ba4cf;
    }
    background-color: #bcd9ea;
  }

  &.blue_dark {
    &::before {
      background-color: #026aa7;
    }
    background-color: #8bbdd9;
  }

  &.gray {
    background-color: #868e96;
  }

  &.sky_light {
    &::before {
      background-color: #8fdfeb;
    }
    background-color: #e4f7fa;
  }

  &.sky {
    &::before {
      background-color: #29cce5;
    }
    background-color: #bdecf3;
  }

  &.sky_dark {
    &::before {
      background-color: #00aecc;
    }
    background-color: #8fdfeb;
  }

  &.pink_light {
    &::before {
      background-color: #f9c2e4;
    }
    background-color: #fef1f9;
  }

  &.pink {
    &::before {
      background-color: #ff8ed4;
    }
    background-color: #fcdcef;
  }

  &.pink_dark {
    &::before {
      background-color: #e568af;
    }
    background-color: #f9c2e4;
  }

  &.lime_light {
    &::before {
      background-color: #b3f1d0;
    }
    background-color: #ecfbf3;
  }

  &.lime {
    &::before {
      background-color: #6deca9;
    }
    background-color: #d3f6e4;
  }

  &.lime_dark {
    &::before {
      background-color: #4ed583;
    }
    background-color: #b3f1d0;
  }

  &.black_light {
    &::before {
      background-color: #505f79;
    }
    background-color: #ebecf0;
  }

  &.black {
    &::before {
      background-color: #344563;
    }
    background-color: #dfe1e6;
  }

  &.black_dark {
    &::before {
      background-color: #091e42;
    }
    background-color: #c1c7d0;
  }
}

.dates,
.customField {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  min-height: 32px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  padding: 6px 12px;
  text-decoration: none;
  background-color: rgba(9, 30, 66, 0.04);
  box-shadow: none;
  border: none;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 85ms;
  transition-timing-function: ease;
}

.customFieldSelectedWithColor {
  color: white;
}

.card-label-green_light {
  background-color: #eef6ec;

  &::before {
    background-color: #b7ddb0;
  }

  &:hover {
    background-color: #d6ecd2;
  }
}

.card-label-green {
  background-color: #61bd4f;

  &:hover {
    background-color: #519839;
  }
}

.card-label-yellow {
  background-color: #f2d600;

  &:hover {
    background-color: #d9b51c;
  }
}

.card-label-orange {
  background-color: #ff9f1a;

  &:hover {
    background-color: #cd8313;
  }
}

.card-label-red {
  background-color: #eb5a46;

  &:hover {
    background-color: #b04632;
  }
}

.card-label-purple {
  background-color: #c377e0;

  &:hover {
    background-color: #89609e;
  }
}

.card-label-blue {
  background-color: #0079bf;

  &:hover {
    background-color: #055a8c;
  }
}

.card-label-pink {
  background-color: #ff78cb;

  &:hover {
    background-color: #c9558f;
  }
}

.card-label-sky {
  background-color: #00c2e0;

  &:hover {
    background-color: #0098b7;
  }
}

.card-label-lime {
  background-color: #51e898;

  &:hover {
    background-color: #4bbf6b;
  }
}

.card-label-black {
  background-color: #344563;

  &:hover {
    background-color: #091e42;
  }
}

.attachmentRow {
  display: flex;
  margin-bottom: 8px;
  cursor: pointer;
  text-decoration: none;
  color: black;

  &:hover {
    background-color: rgba(9, 30, 66, 0.04);
  }
}

.attachmentTile {
  width: 112px;
  height: 80px;
  border-radius: 3px;
  background-color: rgba(9, 30, 66, 0.04);
  color: #5e6c84;
  justify-content: center;
  align-items: center;
  display: flex;
}

.attachmentDetails {
  padding: 8px;

  .name {
    font-weight: 700;
    word-wrap: break-word;
  }

  .date {
    margin-top: 8px;
    color: #5e6c84;
  }
}

.commentRow {
  margin: 16px 32px 0 0;
  display: flex;

  .commentDetails {
    margin-left: 8px;
  }

  .commentHeader {
    margin-bottom: 8px;
  }

  .commentName {
    font-weight: 700;
  }

  .commentDate {
    margin-left: 8px;
    font-size: 12px;
    font-weight: 400;
    white-space: pre;
    color: #5e6c84;
  }
}

.addCommentSection {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .addCommentUser {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.actionComment {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(9, 30, 66, 0.25);
  box-sizing: border-box;
  clear: both;
  display: inline-block;
  margin: 4px 2px 4px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  padding: 0px 8px;
  overflow-wrap: break-word;
  max-width: 500px;

  p {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.cardDescriptionMd {
  overflow-wrap: break-word;
  img {
    width: 100%;
  }
}

.spacer {
  margin-left: 4px;
}

.itemText {
  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  h1,
  h2,
  h3,
  h4 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: unset;
    font-weight: unset;
  }
}
