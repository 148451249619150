.boardContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}

.loadingContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.errorState {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding: 64px;
  padding-top: 200px;
}

.footer {
  position: absolute;
  bottom: 16px;
  left: 0;
  right: 0;
  text-align: center;
}